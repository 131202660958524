/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        $('.archive .woocommerce-loop-product__title').matchHeight();

        // Locations Page division filter
        $('.filter-row').click(function() {
          $(this).find('input').prop("checked", true);
          $('#locations-filter').submit();
        });

        // Results datatable
        $('#ml-results').DataTable();

        // Locations Modal pop up
        $('.infobtn').click(function() {
          var title = $(this).attr('data-title');
          var info = $(this).attr('data-info');
          var phone = $(this).attr('data-phone');
          var email = $(this).attr('data-email');

          $('#myModal .modal-content #phone span').text(phone);
          $('#myModal .modal-content #email span').text(email);
          $('#myModal .modal-content .modal-title').html(title);
          $('#myModal .modal-content #info').html(info);
          $('#myModal .modal-content #phone').attr('href', 'tel:' + phone.replace(/ /g, ""));
          $('#myModal .modal-content #email').attr('href', 'mailto:' + email);
        });

        $('.person-img').click(function() {
          var title = $(this).attr('data-name');
          var info = $(this).attr('data-info');
          var phone = $(this).attr('data-phone');
          var email = $(this).attr('data-email');


          $('#myModal .modal-content #phone span').text(phone);
          $('#myModal .modal-content #phone').attr('href', 'tel:' + phone.replace(/ /g, ""));
          $('#myModal .modal-content #email span').text(email);
          $('#myModal .modal-content #email').attr('href', 'mailto:' + email);
          $('#myModal .modal-content .modal-title').html(title);
          $('#myModal .modal-content #info').html(info);

        });



        // JavaScript to be fired on all pages
        $('#menu-toggle').click(function() {
          // $('.mobile-menu ul').slideToggle();
          $('.mobile-menu').toggleClass('menu-open');
        });

        // Mobile menu dropdowns
        $('.mobile-menu li.menu-item-has-children').on('click', function() {
          $(this).toggleClass('opened');
        });
        $('#menu-group-sub-menu.nav-sub-menu li.menu-item-has-children').on('click', function() {
          $('li.menu-item-has-children.opened').removeClass('opened');
          $(this).toggleClass('opened');
        });


        if ($('.slides').length > 0) {
          setTimeout(function() {
            $('.height').matchHeight({ byRow: false });
          }, 1000);
        }

        if ($('.postsTemplate').length > 0) {
          $('.exc').matchHeight({ byRow: false });
          $('.title').matchHeight({ byRow: false });
        }

        if ($('.event-head').length > 0) {
          $('.event-head').matchHeight({ byRow: false });
          $('.event-excerpt').matchHeight({ byRow: false });
        }

        setTimeout(function() {
          $('.featured-events, .matchheight').matchHeight();
          // $('.event-wrap-container').matchHeight({byRow:false});
        }, 150);

        $('#division-toggle, #division-toggle-mob').click(function() {
          $('.divisions').slideToggle();
        });


        // Flexsilder on Main Template
        if ($('.flexslider').length > 0) {

          $('.flexslider').flexslider({
            animation: "slide",
            //  directionNav: false,
            animationSpeed: 1000,
            slideshow: true,
            touch: true,
            controlNav: true,
            customDirectionNav: $(".banner-custom-navigation a")
          });

        }

        if ($('#slider-product').length > 0) {
          $('#carousel-product').flexslider({
            animation: "slide",
            controlNav: false,
            animationLoop: false,
            slideshow: false,
            itemWidth: 210,
            itemMargin: 5,
            maxItems: 3,
            asNavFor: '#slider-product'
          });

          $('#slider-product').flexslider({
            animation: "slide",
            controlNav: false,
            animationLoop: false,
            slideshow: false,
            sync: "#carousel-product"
          });
        }

        // Slider at the fooetr of Plant Hire
        if ($('.flexslider-cats').length > 0) {

          toshow = $(window).width();
          if (toshow > 992) {
            items = 5;
          } else if ((toshow <= 992) && (toshow > 768)) {
            items = 3;
          } else if ((toshow <= 768) && (toshow > 500)) {
            items = 2;
          } else if (toshow <= 500) {
            items = 1;
          }

          $('.flexslider-cats').flexslider({
            animation: "slide",
            directionNav: true,
            itemWidth: 212,
            maxItems: items,
            itemMargin: 15,
            slideshow: true,
            touch: true,
            controlNav: false
          });
        }

        // Accordion for product attributes table.
        if ($('.shop_attributes .accordion-heading').length > 0) {
          // Set up accordion classes
          $('.shop_attributes tr:not(.accordion-heading)').addClass('accordion-content');
          $('.shop_attributes .accordion-heading').each(function(index) {
            $(this).parent().attr('colspan','2').parent().addClass('attribute-heading-row accordion--closed').attr('data-index',index);
            $(this).parent().next('td').remove();
          });
          $('.shop_attributes .attribute-heading-row').eq(0).addClass('accordion--open').removeClass('accordion--closed');
          $('.shop_attributes .accordion--open').nextUntil('.accordion--closed','.accordion-content').addClass('accordion-content--open');

          // Toggle accordion content
          $('.shop_attributes .attribute-heading-row').on('click',function() {
            $(this).toggleClass('accordion--open accordion--closed');
            $(this).nextUntil('.accordion--closed','.accordion-content').toggleClass('accordion-content--open');
          });
        }

        // Flexslider on woocommerce product page
        if ($('.flexslider-carousel').length > 0) {

          slidestoshow = $(window).width();
          console.log(slidestoshow);
          if (slidestoshow > 992) {
            items = 4;
          } else if ((slidestoshow <= 992) && (slidestoshow > 768)) {
            items = 3;
          } else if ((slidestoshow <= 768) && (slidestoshow > 500)) {
            items = 2;
          } else if (slidestoshow <= 500) {
            items = 1;
          }

          $('.flexslider-carousel').flexslider({
            animation: "slide",
            directionNav: true,
            itemWidth: 212,
            maxItems: items,
            itemMargin: 15,
            slideshow: true,
            touch: true,
            controlNav: false
          });
        }

        // scroll to top
        $('#back-to-top').click(function() {
          $('#back-to-top').tooltip('hide');
          $('body,html').animate({
            scrollTop: 0
          }, 800);
          return false;
        });

        // Hide and show the left menu
        // $('.close-btn').click(function(){
        //   $('body').toggleClass('hub-closed');
        // $('#side-nav').toggleClass('closed');
        // $('#toggle-btn').toggleClass('closed-toggle');
        /* if(!$(this).hasClass('closed-toggle')){
           $('body,html').animate({
             scrollTop: 0
           }, 800);
         } */
        // $('.side-menu-bg').toggleClass('closed');
        // });

        //scroll to fix lower section of hub menu
        $('#sidebar_ctas').scrollToFixed();

        $('#map-container').scrollToFixed({
          marginTop: 20,
          minWidth: 991,
          limit: function() {
            $x = $('#ml-locations .container').offset().top + $('#ml-locations .container').outerHeight(true) - $('#map-container').outerHeight(true);
            return $x;
            // return $('#ml-locations .container').offset().top - $('#map-container').outerHeight() - 10;
          },
        });

        // Home page counters scroll up from zero on scroll into view
        if ($('#counter').length > 0) {
          var a = 0;
          $(window).scroll(function() {
            var oTop = $('#counter').offset().top - window.innerHeight + 330;
            // alert(oTop);
            if (a === 0 && $(window).scrollTop() > oTop) {
              $('.counter-value').each(function() {
                var $this = $(this),
                  countTo = $this.attr('data-count');
                $({
                  countNum: $this.text()
                }).animate({
                  countNum: countTo
                },
                  {
                    duration: 2000,
                    easing: 'swing',
                    step: function() {
                      $this.text(Math.floor(this.countNum));
                    },
                    complete: function() {
                      $this.text(this.countNum);
                      //alert('finished');
                    }
                  });
              });
              a = 1;
            }
          });
        }

        // Filtering on post pages. If not a global page then pick up teh required default division
        if ($('.postsTemplate').length > 0) {
          var mixer;
          //var postsTemplate = document.querySelector('.postsTemplate');
          $('.postsTemplate').each(function(e, item) {
            //console.log(item);
            mixer = mixitup(item, {
              selectors: {
                control: '[data-mixitup-control]'
              },
              callbacks: {
                onMixEnd: function(state) {
                  if ($(item).find('.row').children(':visible').length === 0) {
                    $(this).find('.no-posts').show();
                  }
                },
                onMixStart: function(state) {
                  $(this).find('.no-posts').hide();
                }
              }
            });

            if ($(item).find('.row').children(':visible').length === 0) {
              $(this).find('.no-posts').show();
            }
          });
        }

        // Filtering
        if ($('.mixContainerClass').length > 0) {
          $('.filter-show-all').on('click', function(e) {
            $('#filter-links input[type="checkbox"]').each(function(index) {
              if (index > 0) {
                $(this).prop('checked', false);
              }
            });
          });
          $('#filter-links div  .filter').on('click', function() {
            $('.filter-show-all').prop('checked', false);
          });

          var containerEl = document.querySelector('.mixContainerClass');
          var mixerEvents = mixitup(containerEl, {
          });
        }

        // People page modal click

        $('.page-template-template-people .postitem .img').click(function() {
          $(this).parent().find(".info-btn").trigger('click');
        });


        $('.info-btn').click(function() {
          var name = $(this).parent().find('.name span').text();
          var position = $(this).parent().find('.position span').text();
          var content = $(this).parent().find('.about').html();
          var email = $(this).parent().find('.about').attr('data-email');
          var phone = $(this).parent().find('.about').attr('data-phone');
          if (phone === '') {
            $('#myModal .modal-header #phone').hide();
          } else {
            $('#myModal .modal-header #phone').show();
          }
          if (email === '') {
            $('#myModal .modal-header #email').hide();
          } else {
            $('#myModal .modal-header #email').show();
          }
          $('#myModal .modal-title').html(name + '<br/><span>' + position + '</span>');
          $('#myModal .modal-header #phone span').text(phone);
          $('#myModal .modal-header #email span').text(email);
          $('#myModal .modal-header #phone').attr('href', 'tel:' + phone.replace(/ /g, ""));
          $('#myModal .modal-header #email').attr('href', 'mailto:' + email);
          $('#myModal .modal-body').html(content);
        });

        // Value My Vehicle form select
        $('.heard select').on('change', function() {
          if ((this.value === 'Radio') || (this.value === 'Newspaper') || (this.value === 'Magazine') || (this.value === 'Digital advertising') || (this.value === 'Car Club')) {
            $('#which').slideDown();
            $('#which label span').text(this.value);
          } else {
            $('#which').slideUp();
          }
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    'single': {
      init: function() {
        // JavaScript to be fired on all pages
        var currentDivision = $('#menu_highlight').val();
        if (currentDivision === 'auction') { currentDivision = 'auctions'; }
        $('.divisions .menu-' + currentDivision).addClass('active');
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },

    'tax_product_cat': {
      init: function() {
        // JavaScript to be fired on all pages
        var currentDivision = $('#menu_highlight').val();
        if (currentDivision === 'auction') { currentDivision = 'auctions'; }
        $('.divisions .menu-' + currentDivision).addClass('active');
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Locations page
    'locations': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  // initialise the animation script
  AOS.init({
    disable: 'mobile'
  });

  // Modal Pop to show after 3 minutes of being on the site
  if (!$('body').hasClass('page-template-template-landing-page')) {
    if ($('body').attr('data-pop') === 'showpop') {
      var popCount = parseInt($('body').attr('data-current'));
      if (popCount === 0) {
        popCount = parseInt($('body').attr('data-started'));
      }
      window.setInterval(
        function() {
          popCount = popCount + 1;
          $('body').attr('data-current', popCount);
        }, 1000);

      $(window).scroll(function(event) {

        if ($('body').attr('data-pop') === 'showpop') {
          started = $('body').attr('data-started');
          current = $('body').attr('data-current');

          dif = current - started;
          // console.log(dif);
          if (dif > 180) {
            $('.newsletter-modal').trigger('click');
          }
        }
      });


      $("#newsletterModal").on("hidden.bs.modal", function() {

        $('body').attr('data-pop', 'used');

        var close = 'newspop';

        $.ajax({
          url: ajaxurl,
          type: 'GET',
          cache: false,
          // contentType: "application/json; charset=utf-8",
          data: {
            'action': 'newsletter_ajax_pop',
            'close': close
          },
          dataType: 'json',

          success: function(data) {
            // console.log(data);
          },
          error: function(errorThrown) {
            // console.log(errorThrown);
          }
        });
      });


    }
  }


})(jQuery); // Fully reference jQuery after this point.
